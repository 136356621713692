<template>
  <div id="app">
   <index/>
  </div>
</template>

<script>
 
import index from "./components/Index/index";
 
export default {
  name: 'App',
  components: {
   index,
  }
}
</script>

 