<template lang="">
    <div class="aboutContainer">
        <div class="titleWrapper">
            <div>
                <h1 class="WaterMark">About</h1>
                <h2 class="KnowMore">Know Me More</h2>
            </div>
        </div>

        <div class="textLayout">


            <div class="textContainer">
                <div class="wrapAboutInfo">


                    <h1 class="title">I'm <span style="color: #20c997; font-weight: 600; font-size: 1.75rem !important;">Bryan Powell,</span>  a Web Developer </h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi provident id officiis accusamus
                        eum
                        laboriosam, incidunt pariatur quasi corrupti optio ex ratione officia, eius fuga minus dolorem
                        nostrum minima soluta aliquid nulla consequuntur, facere mollitia totam. Reprehenderit eum sit
                        animi. Incidunt veniam voluptas animi temporibus, nisi consequatur voluptatum fugit aliquam
                        quibusdam odit autem recusandae itaque nam distinctio in expedita libero aliquid magni?
                        Praesentium
                        ab minima libero sunt minus harum iure labore? Eaque odio iure iste nisi vero voluptatem nihil
                        exercitationem dolorem quod accusamus minus quibusdam sit, eos modi eius facere voluptates earum
                        eum
                        porro recusandae accusantium quia. Nulla, ratione autem!.</p>
                </div>
            </div>


            <div class="textContainer">
                <li>
                    <p><b>Name:</b> Bryan Powell</p>
                </li>
                <li>
                    <p><b>Email:</b> bryan.powell370@gmail.com</p>
                </li>
                <li>
                    <p><b>Age:</b> 27</p>
                </li>
               <!--  <li>
                    <p><b>From:</b> Sneads, FL</p>
                </li> -->
                <li><button class="button"> Download CV</button></li>
            </div>

        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="">

</style>