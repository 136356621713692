<template lang="">
   <div class="projectsLayout">
    <div class="titleWrapper">
        <div>


            <h1 class="WaterMark">Portfolio</h1>
            <h2 class="KnowMore">My Work</h2>
        </div>

        <div class="ProjectWrapper">
           <div class="cards small    ecom"></div>
           <div class="cards medium   spotify"></div>
           <div class="cards large    trello"></div>
             
        </div>
    </div>
   </div>


</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>