<template>


    <div class="NavWrapper">
        <div class="nav">

            <li>
                <h1>Bryan</h1>
            </li>


            <li> <a href="#banner"> Home </a> </li>
            <li> <a href="#about"> About </a> </li>
            <li> <a href="#projects"> Projects </a> </li>
            <li> <a href="#contact"> Contact </a> </li>

        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="">

</style>