<template lang="">
    <div class="contactLayout">
        <div class="titleWrapper">
            <div>
                <h1 class="WaterMark">Contact</h1>
                <h2 class="KnowMore">Get In Touch</h2>
            </div>


            <div class="AddressLayout">
                <ul>


                    <li>
                        <h1>Address</h1>
                    </li>
                    <!-- <li>
                        <p>3011 Salem Church Rd, Sneads Fl</p>
                    </li> -->
                    <li>
                        <p>(850) 372-2486</p>
                    </li>

                    <li>
                        <p>bryan.powell370@gmail.com</p>
                    </li>
                    <li>
                           
                    </li>
                </ul>

                <form action="">
                    <input  class="formStyle" placeholder="Name" type="text" name="Name" id="">
                    <input  class="formStyle" placeholder="Email" type="email" name="Email" id="">
                    <textarea class="formStyle" name="" id="" cols="30" rows="10" placeholder="Tell us more about your needs........"></textarea>
             <button class="button">Send Message</button> 
                
                </form>
               
            </div>



        </div>
    </div>
</template>
<script>
    export default {

    }
</script>